<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-button size="sm" variant="danger" class="d-inline-block mr-2 mb-1" @click="openModal('onBlockCalendar')">
            <span class="text-nowrap">{{ msg('blockCalendar') }}</span>
          </b-button>
          <b-button size="sm" variant="primary" class="d-inline-block mr-2 mb-1" @click="openModal('onTreasureContractGenerateMulti')">
            <span class="text-nowrap">{{ msg('generatetreasurerentmulti') }}</span>
          </b-button>
          <b-button size="sm" variant="dark" class="d-inline-block mb-1 mr-2" @click="openModal('onTreasureContractGenerate')">
            <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
          </b-button>
          <b-button size="sm" variant="warning" class="d-inline-block mb-1" @click="()=>showPayments=!showPayments">
          <span class="text-nowrap">
            <i class="fa-solid" :class="{'fa-eye': showPayments, 'fa-eye-low-vision': !showPayments}"></i>
            {{ msg('Payment') }}
          </span>
          </b-button>
        </b-col>
        <b-col cols="4" class="text-right">
          <div class="d-inline-block">
            <b-pagination v-if="pagination != null && pagination.active" :value="pagination.page" :total-rows="pagination.total"  :per-page="pagination.amount" first-number last-number @change="onPaginationChange"/>
          </div>
        </b-col>
      </b-row>
      <time-line-calendar ref="timeCalendar" @onPagination="onPaginationUpdate" @onSelect="onSelection" v-bind:show-payments="showPayments"/>
    </b-card>
    <treasure-contract-rent-modals ref="treasure-contract-modals"/>
    <b-modal size="lg" ref="modal-options" centered hide-footer>
      <b-row v-if="selection">
        <b-col style="white-space: nowrap">{{selection.property}}</b-col>
        <b-col>{{selection.start}} - {{selection.end}}</b-col>
      </b-row>
      <div class="text-center mt-1">
        <b-button size="sm" variant="danger" class="d-inline-block mr-2 mb-1" @click="openModal('onBlockCalendar')">
          <span class="text-nowrap">{{ msg('blockCalendar') }}</span>
        </b-button>
        <b-button size="sm" variant="primary" class="d-inline-block mr-2 mb-1" @click="openModal('onTreasureContractGenerateMulti')">
          <span class="text-nowrap">{{ msg('generatetreasurerentmulti') }}</span>
        </b-button>
        <b-button size="sm" variant="dark" class="d-inline-block mb-1" @click="openModal('onTreasureContractGenerate')">
          <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TimeLineCalendar from "@/components/widgets/calendar/TimeLineCalendar.vue";
import TreasureContractRentModals from "@/components/modals/treasureContractRentModals.vue";

export default {
  name: "TimeLineRentCalendar",
  components: {TreasureContractRentModals, TimeLineCalendar},
  data() {
    return {
      propertyFilterList: [],
      allProperties: [],
      showPayments: false,
      pagination:{page: 1, amount: 100, total:0,  active: false},
      selection: {start: null, end: null, property: null}
    }
  },
  methods: {
    openModal(modalMethod) {
      //console.log('openModal', modalMethod)
      this.$refs['modal-options'].hide()
      let data = this.$refs['timeCalendar'].getSelection()
      this.$refs['treasure-contract-modals'][modalMethod](data.propertyId, data.start, data.end)
    },
    onPaginationUpdate(pagination) {
      this.pagination = pagination
    },
    onPaginationChange(page) {
      this.$refs['timeCalendar'].setResourcesPaginationPage(page)
    },
    onSelection(data) {
      const {start, end, property} = data
      this.selection = {start, end, property:(`${property?.groupId != null ?property?.groupId : "" } ${property?.title}`).trim()};
      this.$refs['modal-options'].show();
    /*  this.propertyFilterList = data.propertyFilterList
      this.allProperties = data.allProperties*/
    }
  }
}
</script>



<style scoped>

</style>
