<template>
  <div class="realEstatePanel">
    <div class="mt-2">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('Block') }}
          </h4>
        </div>
        <b-row class="mt-3">


          <b-col cols="12" md="12" lg="12">
            <b-form-group :label="msg('Real estates')" label-for="realEstate-name">
              <real-estate-picker v-model="listingId"/>
            </b-form-group>
          </b-col>

          <b-col class="mt-2" cols="6" md="6" lg="6">
            <b-form-group :label="msg('Start')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Start date')" rules="required">
                <calendar-picker v-model="start"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="mt-2" cols="6" md="6" lg="6">
            <b-form-group :label="msg('End')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('End date')" rules="required">
                <calendar-picker v-model="end"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>


          <b-col class="mt-2" cols="12" md="12" lg="12">
            <b-form-group :label="msg('Note')" label-for="realEstate-name">
              <b-form-input v-model="note"/>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </div>
    <div class="text-center" style="justify-content:center">
      <!--      <b-button variant="outline-success" class="btn-dialog mr-1" @click="blockCalendar(true)">
              {{ msg('unblock') }}
            </b-button>-->
      <b-button variant="outline-danger" class="btn-dialog mr-1" @click="blockCalendar(false)">
        {{ msg('block') }}
      </b-button>

    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";

export default {
  name: 'blockDatesPanel',
  components: {RealEstatePicker},
  data() {
    return {
      loaded: false,
      error: null,
      listingId: null,
      start: null,
      end: null,
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
  },

  created() {

    this.onLoad()
    this.$root.$on('crm::submitBlock', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitBlock', this.listeners.submit)
  },
  methods: {
    ...mapActions('realEstateContracts', {
      _createContractMulti: 'createContractMulti',
    }),
    ...mapActions('hostaway', ['blockCalendarDates']),


    onLoad() {
      this.loaded = true

    }
    ,
    blockCalendar(isAvailable) {
      let $this = this

      let request = {
        startDate: this.start,
        endDate: this.end,
        isAvailable: isAvailable
      }
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this.blockCalendarDates({listingId: this.listingId, request: request})
            .then(
                (id) => {

                  $this.contractId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id
                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
      })
    },
    setData(start, end){
      this.start = start
      this.end = end
      this.loaded = true
    },
    validate(field) {
      return this.$v.contract[field] != null && this.$v.contract[field].$error === true
    }
    ,
    submit() {
      return this.blockCalendar()
    }

  }


}
</script>

<style scoped>
.orange-label > label {
  color: orange;
}
</style>

