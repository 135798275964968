<template>
  <div>
    <b-modal modal-class="modal-fullscreen" no-fade v-model="dialogs.treasureContractMulti" size="xl"
             id="realEstate-modal" :hide-footer="true" no-close-on-backdrop centered>
      <treasure-contract-rent-panel-multi ref="treasure-contract-rent-panel-multi" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button :disabled="savingContract" variant="outline-success" class="btn-dialog mr-1" @click="submitContract">
          {{ savingContract ? msg('Loading') : msg('Save') }}
        </b-button>
        <b-button :disabled="!savingContract" variant="outline-danger" class="btn-dialog" @click="resetButton">
          {{ msg('Reset') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal modal-class="modal-fullscreen" no-fade v-model="dialogs.treasureContract" size="xl"
             id="realEstate-modal" :hide-footer="true" no-close-on-backdrop centered>
      <treasure-contract-rent-panel ref="treasureContractRentPanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button :disabled="savingContract" variant="outline-success" class="btn-dialog mr-1" @click="submitContract">
          {{ savingContract ? msg('Loading') : msg('Save') }}
        </b-button>
        <b-button :disabled="!savingContract" variant="outline-danger" class="btn-dialog" @click="resetButton">
          {{ msg('Reset') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal modal-class="modal-fullscreen" no-fade v-model="dialogs.blockCalendar" size="lg"
             id="realEstate-modal" :hide-footer="true" no-close-on-backdrop centered>
      <block-dates-panel ref="block-dates-panel" @submitted="onSubmitted"/>
    </b-modal>
  </div>
</template>

<script>
import BlockDatesPanel from "@/views/realEstate/contracts/blockDatesPanel.vue";
import TreasureContractRentPanelMulti from "@/views/realEstate/contracts/treasureContractRentPanelMulti.vue";
import TreasureContractRentPanel from "@/views/realEstate/contracts/treasureContractRentPanel.vue";

export default {
  name: "treasureContractRentModals",
  components: {TreasureContractRentPanel, TreasureContractRentPanelMulti, BlockDatesPanel},
  props: ['pagination'],
  data: () => ({
    dialogs: {
      treasureContract: false,
      blockCalendar: false,
      treasureContractMulti: false
    },
    savingContract: false,
  }),
  methods:{
    onTreasureContractGenerate(propertyId, start, end) {
      let $this = this;
      this.dialogs.treasureContract = true
      setTimeout(()=>{
        if(start !== null && end !== null && $this.$refs['treasureContractRentPanel'] != null) {
          $this.$refs['treasureContractRentPanel'].setData(propertyId, start, end)
        }
      }, 500)
    },
    onTreasureContractGenerateMulti(propertyId, start, end) {
      let $this = this;
      this.dialogs.treasureContractMulti = true
      setTimeout(()=>{
        if(start !== null && end !== null && $this.$refs['treasure-contract-rent-panel-multi'] != null) {
          $this.$refs['treasure-contract-rent-panel-multi'].setData([propertyId], start, end)
        }
      }, 500)
    },
    onBlockCalendar(propertyId, start, end) {
      let $this = this;
      this.dialogs.blockCalendar = true
      setTimeout(()=>{
        if(start !== null && end !== null && $this.$refs['block-dates-panel'] != null) {
          $this.$refs['block-dates-panel'].setData(start, end)
        }
      }, 500)
    },
    resetButton() {
      this.savingContract = false;
    },
    onSubmitted(ans) {
      if (ans.status) {
        this.dialogs.treasureContract = false;
        this.dialogs.treasureContractMulti = false;
        this.dialogs.blockCalendar = false;
        this.savingContract = false;
      }
    },
    submitContract() {
      this.savingContract = true;
      this.$root.$emit('crm::submitContract')
    },

  }
}
</script>


<style scoped>

</style>
